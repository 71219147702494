<template lang="pug">
    article.tb-test(:style="{ marginBottom: margin }")
        header.head
            article-icon.icon(src="hex_green", class="opacity-50")
            ui-title.text(variant="subtitle-2", component="h3") Тест
            article-icon.icon(src="hex_green", class="opacity-50")
        .test-body
            .question(
                v-for="(question, index) in parsedQuestions",
                :key="index"
            )
                ui-paragraph(v-html="question.text")
                .buttons
                    button.positive(@click="() => applyAnswer(index, true)", :class="{ 'active': question.selected === 'positive' }")
                        positive.icon.positive
                        span Да
                    button.negative(@click="() => applyAnswer(index, false)", :class="{ 'active': question.selected === 'negative' }")
                        negative.icon.negative
                        span Нет
        footer.result
            ui-title.title(variant="subtitle-2", component="h3")
                em Результат
            article-icon.icon(v-if="resultText !== null", src="hex")
            ui-title(
                v-if="resultText !== null",
                variant="subtitle-2",
                component="p",
                v-html="resultText",
                ref="result"
            )
</template>

<script>
import UiParagraph from '../../../../../ui/Paragraph.vue';
import UiTitle from '../../../../../ui/Title.vue';
import ArticleIcon from '../../../../Icon.vue';

import { data, results } from '../../../../../../assets/data/articles/1/unique/test.json';

const icons = {
    Positive: () => import(/* webpackChunkName: 'positive' */ '../../../../../../assets/images/icons/hex.svg?inline'),
    Negative: () => import(/* webpackChunkName: 'negative' */ '../../../../../../assets/images/icons/hex_green.svg?inline'),
};

export default {
    name: 'tb-test-desktop',
    components: {
        UiParagraph,
        UiTitle,
        ArticleIcon,
        Positive: icons.Positive,
        Negative: icons.Negative,
    },
    watch: {
        resultText() {
            this.$nextTick(() => this.calcMargin());
        },
    },
    computed: {
        resultText() {
            const answered = this.parsedQuestions.filter(({ selected }) => selected !== false);

            if (answered.length === this.parsedQuestions.length) {
                const answeredTotal = answered.reduce((acc, cv) => {
                    const count = cv.selected === 'positive' ? cv.count : 0;

                    return acc + count;
                }, 0);
                const filteredResults = results.filter((result) => answeredTotal >= result.total);

                return filteredResults[filteredResults.length - 1]?.text || null;
            }

            return null;
        },
    },
    data: () => ({
        parsedQuestions: data.map((question) => ({
            selected: false,
            ...question,
        })),
        margin: null,
    }),
    methods: {
        applyAnswer(questionIndex, isPositive) {
            this.parsedQuestions = this.parsedQuestions.map((question, index) => (questionIndex === index ? {
                ...question,
                selected: isPositive ? 'positive' : 'negative',
            } : question));
        },
        calcMargin() {
            const { $el: node } = this.$refs.result;
            const { height } = node.getBoundingClientRect();

            this.margin = `${(height + 120) / 16}rem`;
        },
    },
};
</script>

<style lang="scss" scoped>
.tb-test {
    width: rem(900);
    margin: rem(120) auto;
    padding: rem(120) rem(100);
    position: relative;
    border: rem(20) solid var(--light-green);
    border-radius: rem(70);

    .head {
        width: rem(380);
        min-width: 420px;
        max-width: 45%;
        padding: rem(32) 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 50%;
        background: url('../../../../../../assets/images/noise.png') var(--color-main-bg);
        border: rem(20) solid var(--light-green);
        border-radius: rem(35);
        transform: translate(-50%, calc(-50% - #{rem(10)}));

        .icon {
            width: rem(53);
            height: rem(45);
            object-fit: contain;
        }

        .text {
            margin: 0 rem(38);
            text-transform: uppercase;
        }
    }

    .result {
        width: rem(300);
        min-width: 280px;
        max-width: 45%;
        padding: rem(32) rem(28);
        position: absolute;
        top: 100%;
        left: 50%;
        background-color: var(--light-green);
        border-radius: rem(22);
        transform: translate(-50%, #{rem(-74)});

        .title {
            text-transform: uppercase;
            text-align: center;
        }

        .icon {
            width: rem(29);
            height: rem(25);
            margin: rem(36) auto rem(12) auto;
            object-fit: contain;
        }
    }

    .test-body {
        width: 100%;

        .question {
            width: 100%;
            margin: rem(21) 0;
        }

        .buttons {
            margin-top: rem(14);

            button {
                margin-right: rem(42);

                .icon {
                    width: rem(39);
                    height: rem(33);

                    path {
                        fill: transparent;
                        transition: fill 0.2s ease-in-out-quad;
                    }
                }

                > span {
                    margin-left: rem(9);
                    position: relative;
                    top: 1px;
                    text-transform: uppercase;
                }

                &:hover,
                &:focus,
                &.active {
                    .icon.positive {
                        path {
                            fill: #a4538f;
                        }
                    }

                    .icon.negative {
                        path {
                            fill: #a8ded8;
                        }
                    }
                }
            }
        }
    }
}
</style>
