<template lang="pug">
    client-only
        tb-test-mobile(v-if="$platform.isMobile")
        tb-test-desktop(v-else)
</template>

<script>
import TbTestDesktop from './Test/Desktop.vue';
import TbTestMobile from './Test/Mobile.vue';

export default {
    name: 'tb-test',
    components: {
        TbTestDesktop,
        TbTestMobile,
    },
};
</script>
