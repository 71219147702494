<template lang="pug">
    .wrap
        aside.test-counter(aria-hidden="true")
            .count-wrap(
                v-for="(question, index) in parsedQuestions",
                :key="index",
                :class="{ 'active': currentIndex >= index }"
            )
                article-icon.icon(src="hex_green_stroke")
                article-icon.icon.active(src="hex")
                span.count {{ index + 1 }}
        article.tb-test(:class="{ 'with-result': resultText !== null }")
            header.head
                ui-title.text(variant="subtitle-2", component="h3") {{ resultText === null ? 'Тест' : 'Результат' }}
            .test-body(v-if="resultText === null")
                .question
                    ui-paragraph(v-html="currentQuestion.text")
                .buttons
                    button.positive(
                        @click="() => applyAnswer(currentIndex, true)",
                        :class="{ 'active': currentQuestion.selected === 'positive' }"
                    )
                        positive.icon.positive
                        span Да
                    button.negative(
                        @click="() => applyAnswer(currentIndex, false)",
                        :class="{ 'active': currentQuestion.selected === 'negative' }"
                    )
                        negative.icon.negative
                        span Нет
            .test-body(v-else)
                .question
                    ui-title(
                        variant="subtitle-2",
                        component="p",
                        v-html="resultText"
                    )
                .buttons
                    article-icon.result-icon(src="hex")
</template>

<script>
import UiParagraph from '../../../../../ui/Paragraph.vue';
import UiTitle from '../../../../../ui/Title.vue';
import ArticleIcon from '../../../../Icon.vue';

import { data, results } from '../../../../../../assets/data/articles/1/unique/test.json';

const icons = {
    Positive: () => import(/* webpackChunkName: 'positive' */ '../../../../../../assets/images/icons/hex.svg?inline'),
    Negative: () => import(/* webpackChunkName: 'negative' */ '../../../../../../assets/images/icons/hex_green.svg?inline'),
};

export default {
    name: 'tb-test-mobile',
    components: {
        UiParagraph,
        UiTitle,
        ArticleIcon,
        Positive: icons.Positive,
        Negative: icons.Negative,
    },
    computed: {
        resultText() {
            const answered = this.parsedQuestions.filter(({ selected }) => selected !== false);

            if (answered.length === this.parsedQuestions.length) {
                const answeredTotal = answered.reduce((acc, cv) => {
                    const count = cv.selected === 'positive' ? cv.count : 0;

                    return acc + count;
                }, 0);
                const filteredResults = results.filter((result) => answeredTotal >= result.total);

                return filteredResults[filteredResults.length - 1]?.text || null;
            }

            return null;
        },
        currentQuestion() {
            return this.parsedQuestions[this.currentIndex];
        },
    },
    data: () => ({
        currentIndex: 0,
        parsedQuestions: data.map((question) => ({
            selected: false,
            ...question,
        })),
    }),
    methods: {
        applyAnswer(questionIndex, isPositive) {
            this.parsedQuestions = this.parsedQuestions.map((question, index) => (questionIndex === index ? {
                ...question,
                selected: isPositive ? 'positive' : 'negative',
            } : question));
            this.currentIndex += 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    padding: 0 var(--offset-x);
}

.test-counter {
    width: 100%;
    margin: rem-mobile(32) 0 rem-mobile(24);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .count-wrap {
        width: vw-mobile(28);
        height: vw-mobile(24);
        margin: 0 vw-mobile(2);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .icon {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            &.active {
                z-index: 1;
                opacity: 0;
                transition: opacity 0.35s ease-in-out-quad;
            }
        }

        .count {
            position: relative;
            z-index: 5;
            font-size: vw-mobile(14);
            color: var(--green);
            opacity: 0.5;
            transition: color 0.25s ease-in-out-quad;
        }

        &.active {
            .icon.active {
                opacity: 1;
            }

            .count {
                color: white;
                opacity: 1;
            }
        }
    }
}

.tb-test {
    width: 100%;
    height: rem-mobile(224);
    margin: rem-mobile(48) 0;
    padding: rem-mobile(24) rem-mobile(18);
    padding-top: rem-mobile(36);
    position: relative;
    border: rem-mobile(6) solid var(--light-green);
    border-radius: rem-mobile(8);
    box-sizing: border-box;

    .head {
        padding: rem-mobile(4) rem-mobile(24);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 50%;
        background: url('../../../../../../assets/images/noise.png') var(--color-main-bg);
        border: rem-mobile(6) solid var(--light-green);
        border-radius: rem-mobile(8);
        transform: translate(-50%, calc(-50% - #{rem-mobile(3)}));

        .text {
            text-transform: uppercase;
        }
    }

    .test-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: space-between;

        .question {
            width: 100%;
            height: calc(100% - #{rem-mobile(24 + 20)});
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        .buttons {
            height: rem-mobile(24);
            margin-top: rem-mobile(20);
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                margin: 0 rem-mobile(10);

                .icon {
                    width: rem-mobile(30);
                    height: rem-mobile(24);

                    path {
                        fill: transparent;
                    }
                }

                > span {
                    margin-left: rem(9);
                    position: relative;
                    top: 1px;
                    text-transform: uppercase;
                }
            }
        }
    }

    &.with-result {
        height: 100%;
        min-height: rem-mobile(224);
        background-color: var(--light-green);

        .head {
            background-color: var(--light-green);
            background-image: none;

            .text {
                color: var(--violet);
            }
        }

        .buttons {
            .result-icon {
                width: rem-mobile(30);
                height: rem-mobile(24);
            }
        }
    }
}
</style>
